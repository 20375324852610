<template>
  <div>
    <!-- <ExpoHeader></ExpoHeader> -->
    <div v-if="obj" class="container details-main">
      <!-- Кнопка возврата -->
      <div class="details-button--exit">
        <a @click="$route.params.fromCatalog ? $router.go(-1) : $router.push('/catalog')" class="button-exit--img"></a>
        <a @click="$route.params.fromCatalog ? $router.go(-1) : $router.push('/catalog')">{{$t('catalogDetails.exit')}}</a>
      </div>
      <!-- /Кнопка возврата -->
      <!-- Заголовок и поиск -->
      <div class="details-name row">
        <div class="col-8 name-object">
          <span>{{ obj.name }}</span>
          <input type="hidden" :value=obj.id class="model-id">
        </div>
      </div>
      <!-- /Заголовок и поиск -->
      <!-- Ссылка и откуда -->
      <div class="details-link--box">
        <div class="details-linkFrom details-from">
          <div class="details-img--linkFrom details-from--img"></div>
          <span class="text">{{ obj.manufacturer }}</span>
        </div>
        <div class="details-linkFrom details-link">
          <div class="details-img--linkFrom details-link--img"></div>
          <a :href="obj.website" target="_blank" class="text" >{{ obj.website }}</a>
        </div>
      </div>
      <!-- /Ссылка и откуда -->
      <!-- Описание и слайдер -->
      <div class="details-more row row-cols-2">
        <div class="details-more--info col-12 col-xxl-5">
          <span>
            {{ obj.description }}
          </span>
          <div class="more-info--specifications">
            <div class="size row">
              <span class="text col">{{$t('catalogDetails.size')}}</span>
              <span class="size-number col">{{ obj.size }}</span>
            </div>
            <div class="heft row">
              <span class="text col">{{$t('catalogDetails.helf')}}</span>
              <span class="heft-number col">{{ obj.materials }}</span>
            </div>
          </div>
          <div class="details-more--button col-12 col-xxl-10">
            <div v-bind:class="likeBtnClass" @click="likeTap()">
              <img src="/images/interface/detalis/like-details.png" class="details-button--none" alt="">
              <span class="details-button--none">{{$t('catalogDetails.like')}}</span>
            </div>
            <div v-bind:class="likedBtnClass">
              <img src="/images/interface/detalis/like-ok.png" class="details-button--ok" alt="">
              <span class="details-button--ok">{{$t('catalogDetails.like-ok')}}</span>
            </div>
          </div>
        </div>
        <div class="details-more--slider col-12 col-xxl-7">
          <div class="swiper-help">
            <div class="swiper-button swiper-button-prev--details">
              <img src="/images/interface/slider/pre_button.png" alt="" class="slider-button">
            </div>
            <div class="swiper-button swiper-button-next--details">
              <img src="/images/interface/slider/next_button.png" alt="" class="slider-button">
            </div>
            <div v-swiper:objImageSwiper="swiperOption"
              class="swiper-container swiper-container--details">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper swiper-wrapper--details">
                <!-- Slides -->
                <div class="swiper-slide swiper-slide-details js-canvas-slide">
                  <img src="/images/interface/main_catalog/360deg.png" alt="" class="deg360">
                  <BabylonCanvas :on-ready="init3d" ref="babylonCanvas"/>
                </div>
                <!-- Show only set images, skip nulls and empty, and unique only -->
                <div :key="im" v-for="im in [obj.img1, obj.img2, obj.img3].filter(x => x)"
                  class="swiper-slide swiper-slide-details">
                  <img :src="swiperImg(im)" alt="">
                </div>
                <div :key="vi" v-for="vi in [obj.videoUrl].filter(x => x)" class="swiper-slide swiper-slide-details">
                  <iframe :src="vi" width="95%" height="100%" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExpoFooter></ExpoFooter>
  </div>
</template>


<script>
import {directive} from 'vue-awesome-swiper';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import {imop, tr} from "@/utils";
import BabylonCanvas from "@/components/BabylonCanvas";
import store from "@/store";
import BabylonHome from './BabylonHome.vue';
// import ExpoHeader from "@/components/ExpoHeader";
import ExpoFooter from "@/components/ExpoFooter";

export default {
  props: ["objectId", "object", "fromCatalog"],
  name: "ExpoCatalogDetails",
  directives: {
    swiper: directive
  },
  components: {
    // ExpoHeader,
    ExpoFooter,
    BabylonCanvas
  },
  computed: {
    obj() {
      return tr(this.object, this.$i18n.locale) || {};
    },
    likedObject() {
      const likes = store.state.userProfile?.objectsLikes || [];
      return likes.contains(this.objectId);
    },
    likeBtnClass() {
      if (this.likedObject) {
        return "details-button details-like--none active";
      }
      return "details-button details-like--none";
    },
    likedBtnClass() {
      if (this.likedObject) {
        return "details-button details-like--ok active";
      }
      return "details-button details-like--ok";
    },
    likeRemuve(){
      if(!(obj.id === this.obj.id)){
        document.querySelector('.details-like--none').classList.remove("active")
        document.querySelector('.details-like--ok').classList.remove("active")
      }
    }
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        loopFillGroupWithBlank: false,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next--details',
          prevEl: '.swiper-button-prev--details',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          // when window width is >= 300px
          300: {
            slidesPerView: 1,
            spaceBetween: 60,
            slidesPerGroup: 1,
          },
        }
      },
      scene: null,
      engine: null,
    };
  },
  methods: {
    likeTap(){
      if (!store.state.user || store.state.user.isAnonymous) {
        this.$router.push({
          name: "Login",
          query: {
             b: true
          }
        })
      } else {
        store.dispatch("likeObject", {objectId: this.objectId}).catch(this.$log.error);
        document.querySelector('.details-like--none').classList.add("active")
        document.querySelector('.details-like--ok').classList.add("active")
      }
    },
    swiperImg(url) {
      return imop(url, 'c_thumb,h_1150');
    },
    init3d() {
      const model = this.$route.params.objectId;

      const canvas = this.$refs.babylonCanvas.$refs.canvas;
      this.$log.info(`Loading model in details: ${model} canvas: ${canvas}`);
      const regexWithCharRange = /[a-z]/;
      this.$log.debug("model="+model);
      if(regexWithCharRange.test(model)){
        document.querySelectorAll(".js-canvas-slide").forEach((item, i) => {
          item.remove();
        });
        return;
      }
      const createDefaultEngine = function () {
        return new BABYLON.Engine(canvas, true, {
          preserveDrawingBuffer: true,
          stencil: true,
          disableWebGL2Support: true
        });
      }.bind(this);

      const createScene = function () {
        // This creates a basic Babylon Scene object (non-mesh)
        const scene = new BABYLON.Scene(this.engine);
        const camera = new BABYLON.ArcRotateCamera("camera", BABYLON.Tools.ToRadians(90), BABYLON.Tools.ToRadians(65), 1.5, BABYLON.Vector3.Zero(), scene);
        camera.lowerRadiusLimit = camera.radius;
        camera.upperRadiusLimit = camera.radius;
        camera.inputs.attached.pointers.panningSensibility=0;
        camera.attachControl(canvas, true);
        this.$log.debug(`Loading model ${"SM_"+model + ".glb"} scene: ${scene} engine: ${this.engine}`)
        BABYLON.SceneLoader.AppendAsync("/js/model_list/", "SM_"+model + ".glb", scene).then(function () {
          scene.createDefaultCamera(true, true, true);
          // scene.createDefaultCameraOrLight(true, true, true);
          scene.activeCamera.alpha += Math.PI;
          scene.clearColor = new BABYLON.Color4(229 / 255, 229 / 255, 229 / 255);
          let lighting = BABYLON.CubeTexture.CreateFromPrefilteredData("https://assets.babylonjs.com/environments/environmentSpecular.env", scene);
          lighting.name = "runyonCanyon";
          lighting.gammaSpace = false;
          scene.environmentTexture = lighting;
          scene.createDefaultSkybox(scene.environmentTexture, true, (scene.activeCamera.maxZ - scene.activeCamera.minZ)/2, 0.3, false);
          scene.cameras[0].upperBetaLimit = 1.5;
          scene.cameras[0].lowerBetaLimit = .5;
          scene.cameras[0].lowerRadiusLimit = scene.cameras[0].radius - (scene.cameras[0].radius / 2);
          scene.cameras[0].upperRadiusLimit = scene.cameras[0].radius + (scene.cameras[0].radius / 2);
          scene.meshes.forEach(item => {
            if(item.material!=null){
              item.material.backFaceCulling = true;
            }
          });

        }).catch((reason) => {
          this.$log.error("Babych AppendAsync failed", reason);
        });
        return scene;
      }.bind(this);
      const initFunction = async function () {
        const asyncEngineCreation = async function () {
          try {
            return createDefaultEngine();
          } catch (e) {
            this.$log.debug("the available createEngine function failed. Creating the default engine instead");
            return createDefaultEngine();
          }
        }.bind(this);
        this.engine = await asyncEngineCreation();
        if (!this.engine) throw 'engine should not be null.';
        this.scene = createScene();
      }.bind(this);
      initFunction().then(() => {
        this.engine.runRenderLoop(this.renderLoop);
      }).catch((reason) => {
        this.$log.error("Babych initFunction failed", reason);
      });
      // Resize
      window.addEventListener("resize", this.onResize);
    },
    onResize() {
      if (this.engine)
      this.engine.resize();
    },
    renderLoop() {
      if (this.scene && this.scene.activeCamera) {
        this.scene.render();
      }
    }
  },
  destroyed() {
    this.$log.debug("Cleaning after destroy!");
    window.removeEventListener("resize", this.onResize);
    if (this.scene) {
      this.$log.debug("dispose babylon scene!");
      this.scene.dispose();
      this.scene="";
    }
    if (this.engine) {
      this.$log.debug("dispose babylon engine!");
      this.engine.dispose();
    }
  }
}

</script>
<style scoped>
  .name-object{
    width: 40.666667% !important;
  }
  .details-main{
    position: relative;
  }
  .details-more--slider {
    position: absolute;
    right: 0%;
    top: calc(18% + 20px);
  }
  @media (max-width: 1400px){
    .details-more--slider {
      position: relative;
      right: inherit;
      top: inherit;
    }
    .name-object{
      width: 70.666667% !important;
    }
  }
  @media (max-width: 400px){
    .name-object{
      width: 80.666667% !important;
    }

  }
</style>
