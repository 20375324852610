import { render, staticRenderFns } from "./ExpoCatalogDetails.vue?vue&type=template&id=f526abe6&scoped=true&"
import script from "./ExpoCatalogDetails.vue?vue&type=script&lang=js&"
export * from "./ExpoCatalogDetails.vue?vue&type=script&lang=js&"
import style0 from "./ExpoCatalogDetails.vue?vue&type=style&index=0&id=f526abe6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f526abe6",
  null
  
)

export default component.exports