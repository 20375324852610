<template>
  <canvas ref='canvas' class="detail-canvas"></canvas>
</template>
<script>
export default {
  props: ['onReady'],
  name: 'BabylonCanvas',
  mounted() {
    this.$props.onReady();
  }
}
</script>
